<template>
  <div>
    <div class="custom-search d-flex justify-content-end">
      <b-form-group>
        <div class="d-flex align-items-center">
          <label class="mr-1">{{ $t('label.search') }}</label>
          <b-form-input
              v-model="searchTerm"
              :placeholder="$t('label.searchPlaceholder')"
              type="text"
              class="d-inline-block"
              @input="globalSearch"
          />
        </div>
      </b-form-group>
    </div>
    <b-overlay
        :show="loading"
        :opacity="0.1"
        spinner-variant="primary"
        spinner-type="grow"
        rounded="sm"
    >
      <vue-good-table
          mode="remote"
          :columns="columns"
          :rows="rows"
          :pagination-options="{
            enabled: true,
            perPage: pageLength
          }"
          @on-row-click="onRowClick"
          @on-sort-change="onSortChange"
          @on-column-filter="onColumnFilter"
      >
        <div slot="emptystate">
          <div class="vgt-center-align vgt-text-disabled">
            {{ $t('table.list.empty') }}
          </div>
        </div>
        <template
            slot="table-row"
            slot-scope="props"
        >

          <!-- Column: Name -->
          <span
              v-if="props.column.field === 'title'"
              class="text-nowrap"
          >
            <span class="text-nowrap">{{ $t('options.'+props.row.title) }}</span>
          </span>

          <span
              v-else-if="props.column.field === 'value'"
          >
            <span>{{ $helpers.excerpt(props.row.value) }}</span>
          </span>

          <!-- Column: Action -->
          <span v-else-if="props.column.field === 'action'">
            <span>
              <b-button
                  size="sm"
                  variant="primary"
                  @click.stop="$router.push({ name: 'modify-option', params: { id: props.row.id }})"
              >
                <feather-icon
                    icon="EditIcon"
                />
              </b-button>
            </span>
          </span>

          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

        <!-- pagination -->
        <template
            slot="pagination-bottom"
            slot-scope="props"
        >
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap">
              {{ ((page - 1) * pageLength) + 1 }} +
            </span>
              <b-form-select
                  v-model="pageLength"
                  :options="pages"
                  class="mx-1"
                  @change="handlePageChange"
              />
              <span class="text-nowrap ">{{ $t('table.list.pagination', {total: dataFromServer.total}) }}</span>
            </div>
            <div>
              <b-pagination
                  :value="1"
                  :total-rows="dataFromServer.total"
                  :per-page="dataFromServer.per_page"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                  @change="handleChangePage"
              >
                <template #prev-text>
                  <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </b-overlay>
  </div>
</template>

<script>
import {
  BPagination, BFormGroup, BFormInput, BFormSelect, BDropdown, BDropdownItem, BButton, BOverlay,
} from 'bootstrap-vue'
import {VueGoodTable} from 'vue-good-table'

export default {
  components: {
    VueGoodTable,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BButton,
    BOverlay,
  },
  data() {
    return {
      role: 'option',
      pageLength: 10,
      dir: false,
      pages: ['10', '20', '50', '100'],
      columns: [
        {
          label: this.$t('form.id'),
          field: 'id',
          sortable: false,
          filterOptions: {
            enabled: true,
            placeholder: this.$t('table.filter.placeholder.id'),
          },
          width: '9rem',
        },
        {
          label: this.$t('form.name'),
          field: 'title',
          sortable: true,
          filterOptions: {
            enabled: true,
            placeholder: this.$t('table.filter.placeholder.name'),
          },
        },
        {
          label: this.$t('form.value'),
          field: 'value',
          sortable: true,
          filterOptions: {
            enabled: true,
            placeholder: this.$t('table.filter.placeholder.value'),
          },
        },
        {
          label: this.$t('form.updatedAt'),
          field: 'updated_at',
          sortable: true,
          filterOptions: {
            enabled: true,
            placeholder: this.$t('table.filter.placeholder.updatedAt'),
          },
          formatFn: this.$formatters.formatLaravelTimestampToDateTime,
        },
      ],
      rows: [],
      page: 1,
      dataFromServer: [],
      filterData: [],
      searchTerm: '',
      sortBy: {
        field: 'id',
        type: 'asc',
      },
      columnFilters: [],
      loading: false,
    }
  },
  created() {

    const actions = [
      {
        label: this.$t('table.header.actions'),
        field: 'action',
        sortable: false,
        width: '10rem',
        tdClass: 'text-center'
      },
    ]

    this.columns = [...this.columns, ...actions]

    this.initData()
  },
  methods: {
    initData() {
      this.loading = true
      const searchParams = {
        page: parseInt(this.page),
        limit: parseInt(this.pageLength),
        query: this.searchTerm,
        columnFilters: this.columnFilters,
        sort: this.sortBy,
      }

      this.$store.dispatch('fetchOptions', searchParams).then(response => {
        this.rows = response.data.entities.data
        this.dataFromServer = response.data.entities
        this.page = this.dataFromServer.current_page
        this.pageLength = parseInt(this.dataFromServer.per_page)
      }).finally(() => {
        this.loading = false
      })
    },
    onRowClick(params) {
      this.$router.push({name: 'modify-option', params: {id: params.row.id}})
    },
    handleChangePage(page) {
      this.page = page
      this.initData()
    },
    handlePageChange(active) {
      this.pageLength = active
      this.page = 1
      this.handleChangePage()
    },
    globalSearch(params) {
      this.page = 1
      this.query = params.searchTerm
      this.initData()
    },
    onSortChange(params) {
      this.page = 1
      this.sortBy = params[0]
      this.initData()
    },
    onColumnFilter(params) {
      this.page = 1
      this.columnFilters = params.columnFilters
      this.initData()
    },
  },
}
</script>
